.coming-soon {
  text-align: center;
  height: 100vh;
}

.coming-soon-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .coming-soon-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
    animation: none;
  }
}

.coming-soon-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  margin-top: 20px;
}

.coming-soon-link {
  color: #61dafb;
}

@keyframes coming-soon-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coming-soon-logo-box {
  display: flex;
}

.rules {
  font-family: auto;
  max-width: 42em;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top:20px;
  text-align: left;
}
.grouper{
  display: inline-flex;
}
.button-spacer{
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 50px;
}